<template>
	<div class="card card-custom card-stretch gutter-b">
		<div class="card-header border-0">
			<h3 class="card-title font-weight-bolder text-dark">{{ name }} - iDirect Beam Switches</h3>
		</div>
		<div class="card-body pt-0 d-flex justify-content-center row">
			<div v-if="beamswitches.length > 0">
				<b-table-simple hover small striped responsive>
					<b-thead>
						<b-tr>
							<b-th class="px-3">Timestamp</b-th>
							<b-th class="px-3">From</b-th>
							<b-th class="px-3">To</b-th>
							<b-th class="px-3">Duration</b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr v-for="(b, i) in beamswitches" :key="i">
							<b-td class="px-3">{{ b.timestamp }}</b-td>
							<b-td class="px-3">{{ b.networkFrom.NetworkName }}</b-td>
							<b-td class="px-3">{{ b.networkTo.NetworkName }}</b-td>
							<b-td class="px-3">{{ b.duration || '-' }}</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</div>
			<div v-else class="w-100 px-2">
				<b-alert show>No beamswitches to report</b-alert>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'IdirectBeamSwitches',
	props: {
		name: {
			type: String,
			required: true,
		},
		deviceId: {
			type: [String, Number],
			required: true,
		},
		networks: {
			type: Array,
			required: true,
		},
		updated: {
			type: Number,
		},
	},
	data() {
		return {
			loaded: false,
			beamswitches: [],
		};
	},
	methods: {
		load() {
			this.loaded = false;
			this.$http.get(`idirect/beamswitches/${this.deviceId}`).then(resp => {
				this.beamswitches = resp.data.data || [];
				this.beamswitches.forEach((b, i) => {
					b.networkTo = this.networks.find(net => net.NetModemId === b.to);
					b.networkFrom = this.networks.find(net => net.NetModemId === b.from);
					if (i !== this.beamswitches.length - 1) {
						let ms = new Date(b.timestamp) - new Date(this.beamswitches[i + 1].timestamp);
						let hours = Math.floor(ms / 1000 / 60 / 60);
						b.duration = hours < 24 ? hours + ' hours' : Math.floor(hours / 24) + ' days ' + (hours % 24) + ' hours';
					}
				});
				this.loaded = true;
			});
		},
	},
	watch: {
		updated() {
			this.load();
		},
	},
	created() {
		this.load();
	},
};
</script>

<style></style>
